.collapsible-header {
  display: flex;
  justify-content: space-between;
  user-select: none;
}
.fa-check-circle {
  color: #28a745;
}
.fa-exclamation-triangle {
  color: #a12920;
}
.fa-circle-notch {
  color: rgb(0, 164, 230);
}
.collapsible-card-body {
  margin: 0rem 1rem 1rem 1rem;
}
.refresh-btn {
  cursor: pointer;
}
.card-body {
  padding: 1rem;
}

.mr-8 {
  margin-right: 0.5rem;
}
.ml-8 {
  margin-left: 0.5rem;
}

.ml-1px {
  margin-left: 1px;
}
.h-1r {
  height: 1rem;
}
.service-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  cursor: pointer;
}

.mr-1px {
  margin-right: 1px;
}

.trigger-header {
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.popoverBody {
  font-family: monospace;
  cursor: pointer;
}
.statusIcon {
  cursor: pointer;
}
.serviceName {
  font-weight: 700;
}
.unDeployed {
  cursor: not-allowed;
  pointer-events: none;
}
