.status-row {
  padding-top: 2rem;
  padding-bottom: 0rem;
  border-bottom: 1px solid rgba(181, 209, 214, 0.87);
  border-right: 1px solid rgba(181, 209, 214, 0.87);
  border-left: 1px solid rgba(181, 209, 214, 0.87);
}
.head-row {
  padding: 0rem;
  border-bottom: 1px solid rgba(181, 209, 214, 0.87);
}

.head-row .col {
  border-right: none;
}

.project-label {
  margin-top: 1rem;
  margin-bottom: 0rem;
  text-align: right;
  font-style: italic;
  font-weight: bold;
  font-size: 1.1rem;
}

.status-container {
  margin-top: 7px;
  margin-bottom: 7px;
}

.btn-outline-custom {
  background-color: rgba(0, 42, 78, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
  font-family: "Roboto";
  font-weight: 600;
}
.btn-outline-custom:hover,
.btn-outline-custom:active {
  color: #fff;
}
.btn-outline-custom:focus {
  box-shadow: none;
}

.card-container {
  border: 1px solid rgba(201, 198, 198, 0.5);
  border-radius: 3px;
}
.card-body {
  border: 1px solid rgba(201, 198, 198, 0.5);
  position: inherit;
}

.status-section-box:last-child .collapsible-status-card:first-child {
  border-top-right-radius: 3px;
}
.status-section-box:last-child .collapsible-status-card:last-child {
  border-bottom-right-radius: 3px;
}

.row-label {
  width: 4%;
  -webkit-writing-mode: vertical-lr;
  writing-mode: vertical-lr;
  writing-mode: tb-lr;
  transform: scale(-1, -1);
  text-align: center;
  font-weight: bold;
}
.row-label-text {
  font-size: 1.75rem;
  font-weight: 700;
  color: #6c757d;
  font-family: "Roboto";
  letter-spacing: 3px;
}

.collapsible-status-card {
  background-color: #fff;
  width: 100%;
}

.status-section-box {
  display: flex;
  width: 48%;
  flex-direction: column;
}

.bb-1c {
  border-bottom: 1px solid rgba(201, 198, 198, 0.25);
}

.br-1c {
  border-right: 1px solid rgba(201, 198, 198, 0.25);
}

.bl-1c {
  border-left: 1px solid rgba(201, 198, 198, 0.25);
}

.bt-1c {
  border-top: 1px solid rgb(201, 198, 198, 0.25);
}

.Collapsible {
  padding: 1rem;
}
.collapsible-status-card .Collapsible__trigger {
  font-family: "Roboto", sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  cursor: pointer;
}
.collapsible-status-card-single {
  width: 100%;
  border-right: 0px !important;
}

.status-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.card-heading {
  display: flex;
}
.card-heading h4 {
  padding-left: 16px;
  width: 50%;
}

.button-container {
  text-align: right;
}

.status-content-container {
  display: flex;
  flex-direction: column;
}

.toggle-cards {
  margin-top: 5px;
  align-self: center;
  cursor: pointer;
}

.expand-cards path:first-child {
  transform: translateY(770px) translateX(-770px);
}
.expand-cards path:nth-child(2) {
  transform: translateY(-770px) translateX(770px);
}

.collapse-cards path:first-child {
  transform: translateY(0px) translateX(0px);
}
.collapse-cards path:nth-child(2) {
  transform: translateY(0px) translateX(0px);
}

.toggle-svg path:first-child,
.toggle-svg path:nth-child(2) {
  transition: transform 0.75s ease-in-out;
  -webkit-transition: transform 0.75s ease-in-out;
}
.mt-2r {
  margin-top: 1.5rem;
}
.disabledWrapper {
  opacity: 0.5;
  cursor: not-allowed !important;
  pointer-events: all;
}
.disabledWrapper > .statusIcon {
  opacity: 0.5;
  cursor: not-allowed !important;
}
