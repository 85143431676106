.overall-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.update-time {
  font-size: 0.85rem;
}

.overall-status-container {
  width: 70%;
  padding: 0.625rem 1rem;
  color: #ffffff;
  border-radius: 5px;
}

.update-time {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 0.75rem;
}

.status-good {
  background-color: #28a745;
}

.status-error {
  background-color: #aa2817;
}

.status-loading {
  background-color: #106e99;
}
.status-loading i {
  font-size: 0.9rem;
}

.fa-white {
  color: white;
}

.overall-status-container span {
  font-size: 1rem;
  font-weight: 700;
  margin-left: 1rem;
}
.overall-status-container .fa-exclamation {
  margin: 0 0.25rem;
}

.header-status div {
  font-size: 1.5rem;
}
.refreshBtn {
  padding: 0.6rem;
  display: flex;
  align-items: center;
  gap: 8px;
}
.refreshBtn img {
  width: 1.2rem;
  height: 1.2rem;
  -webkit-animation: transform 0.2s;
}
.refreshBtn:hover img {
  -webkit-transform: scale(1.2, 1.2);
}
.overallStatus-Wrapper {
  padding-top: 2rem;
}
.refreshIcon {
  margin-bottom: 2px;
}
.textLastUpdated {
  margin-right: 0.5rem;
}
