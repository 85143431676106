.top-heading {
  margin-bottom: 0;
  line-height: 1.5;
}

/* Color Pallete */
.top-heading {
  color: #034b99;
}
.badge-success {
  background-color: #28a745;
}
.badge-danger {
  background-color: #a12920;
}
.badge-secondary {
  background-color: #0056a2;
}
.project-label {
  color: #034b99;
}
.fa-redo-alt {
  color: #034b99;
}

.refresh-button {
  cursor: pointer;
}

.badge-loading {
  color: #fff;
  background-color: #5fbdff;
}
.details-table :first-child {
  margin-bottom: 5px;
}
.details-table {
  margin-bottom: 20px;
}

.info-mark {
  color: #6c757d;
  cursor: pointer;
}

.header-version {
  color: #6c757d;
  font-size: 0.75rem;
  font-style: normal;
  font-family: monospace;
}

.header-hash {
  cursor: pointer;
}

.single-card .card-red {
  background-color: #a12920;
}

.ta-end {
  text-align: end;
}

.service-title {
  color: "#a12920";
}
.seviceStatus {
  font-size: 0.8rem;
}
.buildTime {
  font-style: italic;
  font-weight: 700;
  margin-right: 7px;
}
