@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/roboto/Roboto-Regular.woff");
}

.main {
  padding: 1rem;
  min-height: 100vh;
  background-color: #f6f8fa;
}
.app {
  padding-top: 8rem;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;
  max-width: 1212px;
  font-size: 0.75rem;
}

.Collapsible {
  padding: 0;
}

.trigger-header {
  padding: 0.75rem;
}
