.header-container {
  width: 100%;
  position: -webkit-sticky; /* Safari */
  position: fixed;
  padding: 1.46rem 2.18rem;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-bottom: 1px solid rgba(20, 36, 161, 0.1);
}
.header {
  padding: 0px 0px;
  margin: auto;
  max-width: 1212px;
  min-width: 1212px;
}
.logo {
  max-width: 100%;
  width: 197px;
}
