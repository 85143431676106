.footer-container {
  margin-top: 0.75rem;
}

.line-break {
  border-top: 1px solid rgb(201, 198, 198);
}
.footer-status {
  text-align: right;
  font-size: 0.8rem;
}
.footer-status a:link,
.footer-status a:hover,
.footer-status a:visited,
.footer-status a:active {
  color: #034b99;
  text-decoration: none;
}
.heartIcon {
  color: red;
}
